<template lang="html">
    <div class="container white">
        <top-navigation-bar
            title="Two Variance"
            right-nav="reset"
            @click="doReset"
        />
        <h4 class="mb-3 text-center divider">
            What do you want to test?
        </h4>

        <div class="m-view d-flex">
            <div class="m-view">
                <h2>H<sub>0</sub> :</h2>
            </div>
            <img
                class="formulaImage-char-sm mx-1"
                src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
            >
            <h2 class="mx-4">
                =
            </h2>
            <h3>1</h3>
        </div>
        <div class="m-view d-flex">
            <h2>H<sub>A</sub> : </h2>
            <img
                class="formulaImage-char-sm ml-1"
                src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
            >
            <select
                v-model="selected.value"
                class="form-select-lg mx-3"
            >
                <option
                    v-for="(option, key) in options"
                    :key="key"
                >
                    {{ option.value }}
                </option>
            </select>
            <h3>1</h3>
        </div>
        <button
            class="action-btn"
            @click="showImportModal"
        >
            Import Dataset
        </button>
        <div class="inline-input-view multi d-flex">
            <input-single
                v-model="n1"
                label="𝒏₁"
                style-class="input-single group"
                group
                @enter-pressed="onSubmit()"
            />
            <input-single
                v-model="n2"
                label="𝒏₂"
                style-class="input-single group"
                group
                @enter-pressed="onSubmit()"
            />
        </div>
        <div class="inline-input-view multi d-flex">
            <input-single
                v-model="s1"
                label="𝐬₁"
                style-class="input-single group"
                group
                @enter-pressed="onSubmit()"
            />
            <input-single
                v-model="s2"
                label="𝐬₂"
                style-class="input-single group"
                group
                @enter-pressed="onSubmit()"
            />
        </div>
        <div class="sticky-bottom m-3 mt-5">
            <button
                class="action-btn btn-block"
                block
                @click="onSubmit()"
            >
                Calculate
            </button>
        </div>

        <SelectTwoDatasetsModal
            ref="modalImport"
            @select="importDatasets"
        />
        <modal
            ref="modalOutput"
            title="Two Variance - Result"
            modal-type="hypothesis"
        >
            <template #formula>
                <div class="m-view d-flex">
                    <div class="m-view">
                        <h2>H<sub>0</sub> :</h2>
                    </div>
                    <img
                        class="formulaImage-char-sm mx-1"
                        src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
                    >
                    <h2 class="mx-4">
                        =
                    </h2>
                    <h3>1</h3>
                </div>
                <div class="m-view d-flex">
                    <h2>H<sub>A</sub> : </h2>
                    <img
                        class="formulaImage-char-sm ml-1"
                        src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
                    >
                    <select
                        v-model="selected.value"
                        class="form-select-lg mx-3"
                        @change="onSubmit()"
                    >
                        <option
                            v-for="(option, key) in options"
                            :key="key"
                        >
                            {{ option.value }}
                        </option>
                    </select>
                    <h3>1</h3>
                </div>
            </template>

            <template #image>
                <div class="m-view d-flex justify-content-start">
                    <img
                        class="formulaImage-sm"
                        src="@/assets/HTformulaImgs/variance/F_formula.png"
                    >
                    <h3 class="formulaImage-text">
                        = {{ Fval2 }}
                    </h3>
                </div>
                <h3 class="py-2 ml-2">
                    df1 = n₁ - 1 = {{ df1 }}
                </h3>
                <h3 class="ml-2">
                    df2 = n₂ - 1 = {{ df2 }}
                </h3>
            </template>
            <template #graph>
                <h4 class="my-2 text-center">
                    {{ pOutputDisplay }} = {{ pVal4 }}
                </h4>
            </template>
            <template #conclusion>
                <h3 class="mb-3 text-center">
                    Conclusion:
                </h3>
                <h4 class="text-center pt-2">
                    There is a {{ probDisplay }}% chance of concluding
                </h4>
                <div class="image-row justify-content-center">
                    <img
                        class="formulaImage-char-sm"
                        src="@/assets/HTformulaImgs/variance/Sigma_Ratios.png"
                    >
                    <h4>{{ selected.value }} 1 and being wrong</h4>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
import InputSingle from '@/components/input-single.vue'
import Modal from '@/components/modal.vue'
import SelectTwoDatasetsModal from '@/components/select-two-datasets-modal.vue'
import TopNavigationBar from '@/components/top-navigation-bar.vue'
import hypothesisData from '@/js/hypothesis/hypothesis-data.js'
import { clearData } from '@/js/lib/input-check.js'
import sData from '@/js/summarizeData/summarize-data.js'
import localStore from '@/store/localStore.js'
import _ from 'lodash'

export default {
    name: 'TwoVarianceInput',
    components: {
        TopNavigationBar,
        InputSingle,
        Modal,
        SelectTwoDatasetsModal,
    },
    data () {
        return {
            allData: {},
            computeType: 'hypothesis',
            // input
            n1: '', // Sample Size one
            n2: '', // Sample Size two
            s1: '', // Sample Standard Deviation one
            s2: '', // Sample Standard Deviation two
            // output
            right: '',
            df1: '',
            df2: '',
            Fval2: '',
            upperF2: '',
            lowerF2: '',
            pVal4: '',
            // picker
            options: [
                { value: '<' },
                { value: '>' },
                { value: '≠' },
            ],
            selected: { value: '>' }
        }
    },
    computed: {
        notEqualPdisplay () {
            const output = this.selected.value === '≠' ? '2' : ''
            return output
        },
        pOutputDisplay () {
            let { selected, right, Fval2, upperF2, lowerF2 } = this
            selected = selected.value

            if (selected !== '≠') {
                return `p-Value = P( F ${selected} ${Fval2})`
            } else {
                if (right) {
                    return `p-Value = 2 * P( F > ${upperF2})`
                } else {
                    return `p-Value = 2 * P( F < ${lowerF2})`
                }
            }
        },

        probDisplay () {
            var pVal4 = _.get(this, 'pVal4', '')
            const output = Number((pVal4 * 100).toFixed(2))
            return output
        },
    },
    beforeMount () {
        var store = localStore.getStore('hypothesis-data')
        if (store) {
            _.assign(this, { allData: store })
            var formulaStore = _.get(store, 'TwoV', '')
            if (formulaStore) {
                _.assign(this, formulaStore.input)
            }
        }
    },
    methods: {
        doReset () {
            _.assign(this, clearData(this, ['n1', 'n2', 's1', 's2']))
        },
        showOutputModal () {
            this.$refs.modalOutput.show()
        },
        showImportModal () {
            this.$refs.modalImport.show()
        },
        importDatasets (first, second) {
            const dataFirst = sData.calculateDataCheck(first.items)
            const dataSecond = sData.calculateDataCheck(second.items)
            if (!_.isEmpty(dataFirst.errors)) {
                alert(_.head(dataFirst.errors))
            } else if (!_.isEmpty(dataSecond.errors)) {
                alert(_.head(dataSecond.errors))
            } else {
                this.n1 = dataFirst.output.sampleSize
                this.n2 = dataSecond.output.sampleSize
                this.s1 = dataFirst.output.stdDev
                this.s2 = dataSecond.output.stdDev
            }
        },
        onSubmit () {
            var { allData } = this
            const output = hypothesisData.calculateTwoVariance(this)
            if (!output) {
                console.log('Calculation Error')
            } else {
                _.assign(this, output.data)

                _.set(allData, 'TwoV', { input: output.input, output: output.data })
                localStore.setStoreItem('hypothesis-data', allData)
                this.showOutputModal()
            }
        },
    },
}
</script>
